import React from "react";
import { IconsBoxContainer } from "./styles";

//*TODO: trocar para o .png */
import logoEdufy from "./icons/logo-edufy.png";
import logoHfy from "./icons/logo-hfy.png";
import logoUpfy from "./icons/logo-upfy.png";
import IconLink from "../IconLink";

const icons = [
  {
    id: 1,
    icon: logoUpfy,
    alt: "Gestão",
    href: process.env.REACT_APP_UPFY_URL || "https://app.segfy.com/",
    height: 65,
  },
  {
    id: 2,
    icon: logoHfy,
    alt: "Cotações",
    href: process.env.REACT_APP_HFY_URL || "https://app.segfy.com/multicalculo/novo-orcamento#navr=ed2b085b",
    height: 65,
  },
  {
    id: 3,
    icon: logoEdufy,
    alt: "EAD",
    href: process.env.REACT_APP_EDUFY_URL || "https://edu.segfy.com/",
    height: 65,
  },

];

export const IconsBox = () => {
  return (
    <IconsBoxContainer>
      {icons.map((item) => (
        <IconLink
          alt={item.alt}
          href={item.href}
          icon={item.icon}
          height={item.height}
          key={item.id}
        ></IconLink>
      ))}
    </IconsBoxContainer>
  );
};
