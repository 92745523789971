import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'OpenSans-Regular', Helvetica, Sans-Serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  ::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
      background-color: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {

  border-radius: 10px;
      background: transparent;
}

/* Handle on hover */
&:hover::-webkit-scrollbar-thumb {

  background: #dedede;
}

  /* div, ul, textarea {
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: #dedede;
    }
  } */

  
`;

export default GlobalStyle;
