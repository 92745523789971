import React from "react";
import ReactDOM from "react-dom/client";

import App from "../App";
import GlobalStyle from "../globalStyles";

export function render(props: any) {
  const { container } = props;

  const root = ReactDOM.createRoot(
    container
      ? container.querySelector("#rootCandyBox")
      : (document.getElementById("rootCandyBox") as HTMLElement)
  );
  root.render(
    <React.StrictMode>
      <GlobalStyle />
      <App />
    </React.StrictMode>
  );
}
