import ReactDOM from 'react-dom';
import { render } from './render';


export async function bootstrap() {}

export async function mount(props: any) {
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector('#rootCandyBox')
      : document.querySelector('#rootCandyBox'),
  );
}
