import styled from "styled-components";

export const IconLinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-radius: 12px;
  padding: 10px;
  transition: background-color 250ms ease-in-out;

  & span {
    margin-top: 0.4em;
    color: inherit;
    font-size: 12px;
    color:  #807973;
  }

  &:hover {
    background-color: #faf7f2;
  }

  &:hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
`;
