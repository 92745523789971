import styled from "styled-components";

export const IconsBoxContainer = styled.div`
  height: 200px;
  overflow: auto;
  display: grid;
  grid-template-columns: 110px 110px;
  grid-auto-rows: 100px;
  justify-content: center;
`;
