import "./public-path";
import { render } from "./system/render";
import { bootstrap, mount, unmount } from "./system/qiankun";

// eslint-disable-next-line
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({});
}

export { bootstrap, mount, unmount };
