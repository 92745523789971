import React from "react";
import { IconLinkContainer } from "./styles";

export type TIconLink = {
  icon: string;
  alt: string;
  href: string;
  height: number;
};

const IconLink: React.FC<TIconLink> = ({ icon, alt, href, height }) => {
  return (
    <IconLinkContainer
      href={href}
      onClick={() => {
        const msg = {
          action: "close",
        };

        window.parent.postMessage(
          msg,
          `${process.env.REACT_APP_GOFY_URL || "https://gofy.segfy.com/"}`
        );
        window.parent.postMessage(
          msg,
          `${process.env.REACT_APP_UPFY_URL || "https://app.segfy.com"}`
        );
        window.parent.postMessage(msg, "http://localhost:8080");
      }}
      target="_blank"
      rel="noreferrer"
    >
      <img height={height} src={icon} alt={alt} />

      <span style={{ textAlign: "center" }}>{alt}</span>
    </IconLinkContainer>
  );
};

export default IconLink;
